<template>
    <div v-on:keyup.enter="submitForm('loginForm')"
         class="login-form-block mx-4 px-4 d-flex flex-fill align-content-center align-items-center justify-content-center position-relative"
         style="max-width: 100%; min-width: 32rem; min-height: 440px;">
        <!-- <div class="bg-image"></div> -->
         <!-- <div><img src="/img/login_block_bg.svg" style="width: 100%;" /></div> -->
        <div v-loading="isLoading"
             style="background-color: rgb(0,0,0, 0);"
             class="input-login-block d-flex flex-grow-1 align-content-center justify-center flex-row"> 
            <v-card-text>
                <!-- <div class="container-eyes m-auto mb-4 w-auto align-items-center justify-content-center">
                    <div class="eyes"></div>
                    <div class="eyes"></div>
                </div> -->
                <!-- <div class="layout column align-center mx-4 mt-3">
                    <a href="javascript:void(0);" class="d-block m-auto text-center" target="_blank">
                        <el-tooltip class="item" effect="light" placement="right">
                            <img class="my-4 mb-0 mt-0 irender-logo-text"
                                 :src="$appLogoWhite"
                                 style="height: 3rem; max-width: 60%; width: auto;" />
                        </el-tooltip>
                    </a>
                    <span style="font-size: 1rem"
                          class="no-select mt-0 flex text-white mt-0 mb-4">
                        <strong>Administration Panel</strong>
                    </span>
                </div> -->

                <div style="margin-top: 8em; margin-left: 5em; margin-right: 5em;">
                    <el-form :model="loginForm" :rules="rules" ref="loginForm">
                    <!-- Username -->
                        <el-form-item prop="username" class="d-flex justify-content-center">
                            <el-input style="width: 25rem"
                                    placeholder="Email or username..."
                                    prefix-icon="fas fa-user"
                                    type="text"
                                    clearable
                                    v-model="loginForm.username"
                                    autocomplete="on"></el-input>
                        </el-form-item>

                        <!-- Password -->
                        <div class="mt-5 pt-2">
                            <el-form-item prop="password" class="d-flex justify-content-center">
                                <el-input style="width: 25rem"
                                        placeholder="Type your password..."
                                        show-password
                                        prefix-icon="fas fa-lock"
                                        type="password"
                                        v-model="loginForm.password"
                                        autocomplete="off"></el-input>
                            </el-form-item>
                        </div>

                        <div class="d-flex justify-content-center mt-5 pt-2"
                            v-if="enabledRecaptcha">
                            <vue-recaptcha sitekey="6LcdCMkiAAAAALwKP4uCVPDugMduu9VIngKPAwzB"
                                        ref="recaptchaComp"
                                        @verify="verifyRecaptcha"
                                        @expired="expiredRecaptcha"
                                        @render="renderRecaptcha"
                                        @error="errorRecaptcha" />
                        </div>
                        <!-- Submit -->
                        <el-form-item class="d-flex justify-content-center mt-4 mb-0">
                            <el-button style="width: 30rem; border: none;"
                                    :disabled="!canLogin"
                                    @click="submitForm('loginForm')"
                                    type="primary" class=" login-btn-style">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <!--<div class="text-center mt-3">
                <VueCountdown :time="time">
                    <template slot-scope="props">
                        <div>
                            <div><h4><strong class="text-primary">New year 2021</strong></h4></div>
                            <h5><strong>{{ props.days }} days, {{ props.hours }} hours, {{ props.minutes }} minutes, {{ props.seconds }} seconds.</strong></h5>
                        </div>
                    </template>
                </VueCountdown>
            </div>-->
            </v-card-text>
        </div>

        <!-- Modal enable 2 FA-->
        <div class="modal fade"
             id="loginTfaModal"
             tabindex="-1"
             role="dialog"
             aria-labelledby="loginTfaModal"
             aria-hidden="true"
             style="overflow: hidden; z-index: 999999 !important;">
            <div class="modal-dialog modal-dialog-centered modal-lg-custom"
                 style="width: 95%; max-width: 50rem !important">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <strong class="text-muted">
                                <i class="el-icon-mobile-phone text-primary mr-2"></i>&nbsp; Two-factor authentication
                            </strong>
                        </h5>
                        <button type="button"
                                class="close"
                                @click="closeLoginTfaModal()"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body" v-loading="is2FALoading">
                        <div class="m-auto d-block">
                            <div class="text-center">
                                <p>
                                    Type 6-digit code from <strong>Google Authenticator</strong><br />
                                    to verify your authencation
                                </p>
                            </div>
                            <div class="text-center PIN-CODE"
                                 style="font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif">
                                <CodeInput ref="codeInput"
                                            :loading="false"
                                           class="input m-auto bg-white"
                                           v-on:change="onPINChange"
                                           v-on:complete="onPINComplete"
                                           :autoFocus="true"
                                           style="border-radius: 5px" />
                            </div>
                            <div class="text-center text-danger mt-2"
                                 v-if="twoFactorData.pinSubmitError">
                                {{ twoFactorData.pinSubmitError }}
                            </div>
                            <!--<div class="mt-6">
                                <div class="d-flex justify-content-center" v-if="enabledRecaptcha">
                                    <vue-recaptcha sitekey="6LcdCMkiAAAAALwKP4uCVPDugMduu9VIngKPAwzB"
                                                   ref="recaptchaComp"
                                                   @verify="verifyRecaptcha"
                                                   @expired="expiredRecaptcha"
                                                   @render="renderRecaptcha"
                                                   @error="errorRecaptcha" />
                                </div>
                            </div>--> 
                            <div class="mt-8 mb-8 d-block text-center">
                                <el-button @click="cancelTfaBtn" class="d-inline mr-3"
                                            style="width: auto !important;">
                                    Cancel
                                </el-button>
                                <el-button type="primary"  class="d-inline ml-3" style="width: auto !important;"
                                           :disabled="!twoFactorData.pinInputCompleted || !canLoginWithTwoFactor"
                                           @click="submitTfaBtn">
                                    Authencate
                                </el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import baseComponent from "@/scripts/baseComponent";
    import authApi from "@/api/common/auth";
    import commonActionHandle from "@/commonActionHandle";
    import { VueRecaptcha } from 'vue-recaptcha';
    import CodeInput from "vue-verification-code-input";
    import CustomCodeInput from "@/views/auth/CustomCodeInput.vue";
    const twoFactorEnabled = process.env.VUE_APP_TWO_FACTOR_ENABLED === "true";
    export default {
        extends: baseComponent,
        components: { VueRecaptcha, CodeInput: CustomCodeInput },
        data() {
            var now = new Date();
            var newYear = new Date(now.getFullYear() + 1, 0, 1);
            return {
                time: newYear - now,
                isLoading: false,
                is2FALoading: false,
                rememberPassword: false,
                loginForm: {
                    appCode: "pirender",
                    username: "",
                    password: "",
                    recaptchaToken: '',
                },
                rules: {
                    username: [
                        {
                            required: true,
                            message: "Username must not be blank",
                            trigger: "blur"
                        }
                    ],
                    password: [
                        {
                            required: true,
                            message: "Password must not be blank",
                            trigger: "blur"
                        }
                    ]
                },
                enabledRecaptcha: true,
                twoFactorEnabled: twoFactorEnabled,
                twoFactorData: {
                    isTwoFactorLoading: false,
                    centerDialogVisible: false,
                    cancelPINDialogAction: null,
                    submitPINDialogAction: null,
                    pinInputCompleted: false,
                    pinInputValue: '',
                    pinSubmitError: null,
                },
            };
        },
        computed: {
            canLogin() {
                return ((!this.enabledRecaptcha || this.loginForm.recaptchaToken !== '') && this.loginForm.username !== '' && this.loginForm.password !== '');
            },
            canLoginWithTwoFactor() {
                return ((!this.enabledRecaptcha || this.loginForm.recaptchaToken !== '') && this.twoFactorData.pinInputValue.length === 6);
            }
        },
        mounted() {
            
        },
        methods: {
            openLoginTfaModal() {
                this.$nextTick(() => {
                    $("#loginTfaModal").modal({
                        backdrop: "static",
                        keyboard: true,
                    });
                });
            },
            closeLoginTfaModal() {
                $("#loginTfaModal").modal("hide");
            },
            performAuthencate() {
                authApi
                    .authLogin(this.loginForm)
                    .then((response_data) => {
                        location.href = "/dashboard";
                    })
                    .catch(error => {
                        this.resetRecaptcha(); 
                        console.error("error", error);
                        this.isLoading = false;
                        this.is2FALoading = false;
                        if (typeof stringValue === "string") {
                            this.resetAll();
                            commonActionHandle.showUnknowError("Login failed");
                        } else {
                            setTimeout((this.isLoading = false), 2000);
                            let responseData = error.data;
                            if (responseData) {
                                this.resetAll();
                                commonActionHandle.showCustomError(
                                    "Login failed",
                                    responseData.message
                                );
                            } else {
                                this.resetAll();
                                commonActionHandle.showUnknowError("Login failed");
                            }
                        }
                    });
            },
            submitForm(formName) {
                this.$refs[formName].validate(valid => {
                    if (valid) {
                        this.isLoading = true;
                        if (this.twoFactorEnabled) {
                            authApi
                                .precheckTwoFactorEnabled(this.loginForm)
                                .then((response_data) => {
                                    if (response_data.data && response_data.data.result === 0) {
                                        if (response_data.data.data) {
                                            this.isLoading = false;
                                            this.showPinCodeInput(() => {
                                                this.resetAll();
                                                this.closeLoginTfaModal();
                                            }, () => {
                                                this.is2FALoading = true;
                                                this.loginForm.twoFactorAuthentionCode = this.twoFactorData.pinInputValue;
                                                this.performAuthencate();
                                            });
                                        }
                                        else {
                                            this.performAuthencate();
                                        }
                                    }
                                    else {
                                        this.isLoading = false;
                                        commonActionHandle.showCustomError(
                                            "Login failed",
                                            response_data.data.message
                                        );
                                    }
                                })
                                .catch(error => {
                                    console.error("error", error);
                                    this.isLoading = false;
                                    if (typeof stringValue === "string") {
                                        this.resetAll();
                                        commonActionHandle.showUnknowError("Login failed");
                                    } else {
                                        setTimeout((this.isLoading = false), 2000);
                                        let responseData = error.data;
                                        if (responseData) {
                                            this.resetAll();
                                            commonActionHandle.showCustomError(
                                                "Login failed",
                                                responseData.message
                                            );
                                        } else {
                                            this.resetAll();
                                            commonActionHandle.showUnknowError("Login failed");
                                        }
                                    }
                                });
                        }
                        else this.performAuthencate();

                    } else {
                        this.resetAll();
                        this.isLoading = false;
                        return false;
                    }
                });
            },
            openComponentView() {
                
            },
            verifyRecaptcha(response) {
                //console.log("verifyRecaptcha", response);
                this.$set(this.loginForm, "recaptchaToken", response);
            },
            expiredRecaptcha() {
                //console.log("expiredRecaptcha");
            },
            renderRecaptcha(id) {
                //console.log("renderRecaptcha", id);
            },
            errorRecaptcha() {
                //console.log("errorRecaptcha");
            },
            resetRecaptcha() {
                if (this.enabledRecaptcha) {
                    console.log('resetCapcha')
                    this.$set(this.loginForm, "recaptchaToken", "");
                    this.$refs.recaptchaComp.reset();
                    this.enabledRecaptcha = false;
                    this.$nextTick(() => {
                        this.enabledRecaptcha = true;
                    });
                }
            },
            showPinCodeInput(cancelAction, confirmAction) {
                this.twoFactorData.cancelPINDialogAction = cancelAction;
                this.twoFactorData.submitPINDialogAction = confirmAction;
                this.openLoginTfaModal();
                this.$nextTick(() => {
                    $('.PIN-CODE .react-code-input > input:first-child').focus();
                    if(this.$refs.codeInput) this.$refs.codeInput.focusInput();
                });
            },
            onPINChange(v) {
                if (v.length < 6) {
                    this.twoFactorData.pinInputCompleted = false;
                    this.twoFactorData.pinInputValue = v;
                }
            },
            onPINComplete(v) {
                if (v.length === 6) {
                    this.twoFactorData.pinInputCompleted = true;
                    this.twoFactorData.pinInputValue = v;
                }
            },
            resetAll() {
                this.loginForm.twoFactorAuthentionCode = null;

                this.twoFactorData.pinInputCompleted = false;
                this.twoFactorData.pinInputValue = '';
                this.twoFactorData.centerDialogVisible = false;
                this.twoFactorData.pinSubmitError = null;
                $('.PIN-CODE .react-code-input > input').val('');
                $('.PIN-CODE .react-code-input > input:first-child').focus();
                if(this.$refs.codeInput) this.$refs.codeInput.focusInput();
                this.twoFactorData.isTwoFactorLoading = false;
            },
            cancelTfaBtn() {
                this.twoFactorData.centerDialogVisible = false;
                if (this.twoFactorData.cancelPINDialogAction) this.twoFactorData.cancelPINDialogAction();
            },
            submitTfaBtn() {
                if (this.twoFactorData.submitPINDialogAction) this.twoFactorData.submitPINDialogAction();
            }
        }
    };
</script>

<style>
    .bg-image {
        /* The image used */
        background: url("/img/bg-login-block.jpg");
        /* Add the blur effect */
        filter: blur(2px);
        -webkit-filter: blur(2px);
        /* Full height */
        height: 100%;
        /* Center and scale the image nicely */
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        border-radius: 20px !important;
    }

    .input-login-block {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 9999;
        /* border: 3px solid #f1f1f1; */
        border-radius: 20px !important;
        /* -webkit-box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.75); */
    }

    .el-input .el-input__inner {
        border-radius: 0 !important;
        padding-right: 15px;
        padding-left: 40px;
        background: none !important;
        border: none !important;
        color: #000;
    }

    .el-input .el-input__inner::placeholder {
        color: #3d3d3d;
        opacity: 1; /* Firefox */
    }

    .el-input .el-input__suffix-inner {
        margin-right: 5px;
        color: #000;
    }

    .el-input .el-input__prefix{
        margin-left: 6px;
        color: #000;
    }

    .el-input .el-input__prefix i{
        color: #000 !important;
    }

    .el-form-item__error {
        color: #fff;
        padding-top: 7px;
        padding-left: 15px;
    }

    .el-input  {
        margin-right: 5px;
        background-image: url('/img/input-login-bg.svg');
        background-size: 100% 100%;
        border: none !important;
    }

    

    .container-eyes {
        display: flex;
    }

    .container-eyes .eyes {
        position: relative;
        width: 100px;
        height: 100px;
        display: block;
        background-color: #fff;
        margin: 0 10px;
        border-radius: 50%;
        box-shadow: 0 5px 45px rgba(0, 0, 0, 0.2), inset 0 0 15px #f5af19,
        inset 0 0 25px #f5af19;
    }
    .container-eyes .eyes::before {
        content: "";
        top: 50%;
        left: 35px;
        transform: translate(-50%, -50%);
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: #000;
        position: absolute;
        border: 10px solid skyblue;
        box-sizing: border-box;
    }

    .login-btn-style {
        background-image: url('/img/login-btn-bg.svg') !important;
        background-size: 100% 100%;
        background-color: transparent !important;
        background-repeat: no-repeat !important;
        border: none !important;        
        height: 4rem !important;
        width: 100% !important;
    }

    .login-btn-style:disabled{
        opacity: 0.8;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0px 1000px white inset !important;
    }
</style>